<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout wrap>
      <v-flex md12>
        <!--  -->
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    v-model="reportSearch.customerType"
                    :items="customerTypeList"
                    :label="$t('history.transactionHistory.customer')"
                    clearable
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                      chips
                    >
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    v-model="reportSearch.timeSelectType"
                    :items="reportTimeList"
                    :label="$t('report.time')"
                    :clear-icon-cb="onClearClicked"
                    clearable
                    @change="onChangeTimeSelect(reportSearch.timeSelectType)"
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                      chips
                    >
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  v-show="isShowSelectMonth"
                  xs12
                  md3>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateMonthFormatted"
                        :label="$t('common.month')"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        clearable
                        @blur="month = parseDateMonth(dateMonthFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dateMonthSearch"
                      no-title
                      type="month"
                      @input="menu = false"
                      @change="onChangeMonthData(dateMonthSearch)"
                    />
                  </v-menu>
                  <!-- <v-date-picker
                    v-model="dateMonthFormatted"
                    type="month"
                    show-current="2013-07"
                  /> -->
                </v-flex>
                <!--  -->
                <v-flex
                  v-show="isShowSelectDate"
                  xs12
                  md3>
                  <v-menu
                    ref="menu"
                    v-model="datePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateFormatted"
                        :label="$t('common.date')"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        clearable
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dateSearch"
                      no-title
                      type="date"
                      @input="datePicker = false"
                      @change="onChangeDateData(dateSearch)"
                    />
                  </v-menu>
                  <!-- <v-date-picker
                    v-model="dateFormatted"
                    type="month"
                    show-current="2013-07"
                  /> -->
                </v-flex>
                <!--  -->
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch($event)"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('sideBar.reportManagement')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container
              py-0>
              <v-layout
                pa-2
                wrap>
                <table
                  id="reportListTable"
                  class="table table-hover">
                  <tbody>
                    <tr
                      v-for="(item, index) in reportListData"
                      :key="item+index">
                      <th
                        class="text-right"
                        scope="row">
                        {{ formatInteger(item.value) }}
                      </th>
                      <td>{{ $t(getLabelText(item.type)) }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>

<script>
import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import { mapActions, mapGetters } from 'vuex'
import ReportTimeSelect from 'enum/reportTimeSelect'
import ToastType from 'enum/toastType'
import moment from 'moment'
export default {
  components: {

  },
  data () {
    return {
      menu: false,
      dateMonthSearch: null,
      dateMonthFormatted: null,
      customerTypeList: stringUtils.customerTypeList,
      reportTimeList: stringUtils.reportTimeList,
      reportSearch: {
        customerType: null,
        timeSelectType: 2,
        startDate: '',
        endDate: ''
      },
      isShowSelectMonth: false,
      reportListData: [],
      reportLabelTextList: stringUtils.reportLabelTextList,
      datePicker: false,
      dateSearch: null,
      dateFormatted: null,
      isShowSelectDate: false
    }
  },
  computed: {
    // computedDateFormatted () {
    //   return this.formatDate(this.dateMonthSearch)
    // },
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_DATA_FOR_PAGE_REPORT_DATA'
    ])
  },
  watch: {
    dateMonthSearch (val) {
      this.dateMonthFormatted = this.formatDateMonth(this.dateMonthSearch)
    },
    dateSearch (val) {
      this.dateFormatted = this.formatDate(this.dateSearch)
    },
    GET_DATA_FOR_PAGE_REPORT_DATA: function () {
      let data = this.GET_DATA_FOR_PAGE_REPORT_DATA
      this.reportListData = []
      this.reportListData = data
    }
  },
  created () {
    this.onChangeTimeSelect(this.reportSearch.timeSelectType)
    this.getReportData()
  },
  methods: {
    formatInteger: function (num) {
      num = functionUtils.formatInteger(num && num.toString())
      return functionUtils.convertFormatNumberToMoney(num)
    },
    /**
     * Format date
     */
    formatDateMonth (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      return `${month}/${year}`
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    /**
     * Parse date
     */
    parseDateMonth (date) {
      if (!date) return null
      const [month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    onChangeTimeSelect: function (value) {
      let toFormat = 'YYYY-MM-DD HH:mm:ss'
      this.reportSearch.startDate = ''
      this.reportSearch.endDate = ''
      if (value === ReportTimeSelect.YESTERDAY) {
        this.isShowSelectMonth = false
        this.isShowSelectDate = false
        let startDate = this.getStartOfDayUTC(moment().subtract(1, 'day'), toFormat)
        let endDate = this.getEndOfDayUTC(moment().subtract(1, 'day'), toFormat)
        this.reportSearch.startDate = startDate
        this.reportSearch.endDate = endDate
      } else if (value === ReportTimeSelect.TODAY) {
        this.isShowSelectMonth = false
        this.isShowSelectDate = false
        let startDate = this.getStartOfDayUTC(moment(), toFormat)
        let endDate = this.getEndOfDayUTC(moment(), toFormat)
        this.reportSearch.startDate = startDate
        this.reportSearch.endDate = endDate
      } else if (value === ReportTimeSelect.SEVEN_DAYS_AGO) {
        this.isShowSelectMonth = false
        this.isShowSelectDate = false
        let sevenDaysAgo = moment().subtract(7, 'day')
        let startDate = this.getStartOfDayUTC(sevenDaysAgo, toFormat)
        let endDate = this.getEndOfDayUTC(moment(), toFormat)
        this.reportSearch.startDate = startDate
        this.reportSearch.endDate = endDate
      } else if (value === ReportTimeSelect.THIRTY_DAYS_AGO) {
        this.isShowSelectMonth = false
        this.isShowSelectDate = false
        let thirtyDaysAgo = moment().subtract(30, 'day')
        let startDate = this.getStartOfDayUTC(thirtyDaysAgo, toFormat)
        let endDate = this.getEndOfDayUTC(moment(), toFormat)
        this.reportSearch.startDate = startDate
        this.reportSearch.endDate = endDate
      } else if (value === ReportTimeSelect.SELECT_MONTH) {
        this.isShowSelectMonth = true
        this.isShowSelectDate = false
        this.dateMonthSearch = null
        this.dateSearch = null
      } else if (value === ReportTimeSelect.SELECT_DATE) {
        this.isShowSelectMonth = false
        this.isShowSelectDate = true
        this.dateMonthSearch = null
        this.dateSearch = null
      }
    },
    getStartOfDayUTC: function (dateValue, toFormat) {
      return dateValue.startOf('day').utc().format(toFormat)
    },
    getEndOfDayUTC: function (dateValue, toFormat) {
      return dateValue.endOf('day').utc().format(toFormat)
    },
    onChangeMonthData: function (date) {
      let toFormat = 'YYYY-MM-DD HH:mm:ss'
      let startDate = moment(`${date}-01`)
      let endDate = moment(date).endOf('month')
      startDate = this.getStartOfDayUTC(startDate, toFormat)
      endDate = this.getEndOfDayUTC(endDate, toFormat)
      this.reportSearch.startDate = startDate
      this.reportSearch.endDate = endDate
    },
    onChangeDateData: function (dateSearch) {
      let toFormat = 'YYYY-MM-DD HH:mm:ss'
      let startDate = moment(dateSearch)
      let endDate = moment(dateSearch)
      startDate = this.getStartOfDayUTC(startDate, toFormat)
      endDate = this.getEndOfDayUTC(endDate, toFormat)
      this.reportSearch.startDate = startDate
      this.reportSearch.endDate = endDate
    },
    onClearClicked: function () {
      this.reportSearch.timeSelectType = null
      this.isShowSelectMonth = false
      this.dateMonthSearch = null
      this.dateSearch = null
    },
    onSearch: function (event) {
      event.preventDefault()
      if (this.isShowSelectMonth && this.dateMonthSearch == null) {
        this.ON_SHOW_TOAST({
          message: this.$t('common.month'),
          styleType: ToastType.ERROR
        })
      } else if (this.isShowSelectDate && this.dateSearch == null) {
        this.ON_SHOW_TOAST({
          message: this.$t('common.date'),
          styleType: ToastType.ERROR
        })
      } else {
        this.getReportData()
      }
    },
    getReportData: function () {
      let filter = {
        params: {
          start_time: this.reportSearch.startDate,
          end_time: this.reportSearch.endDate,
          user_type: this.reportSearch.customerType
        }
      }
      this.GET_DATA_FOR_PAGE_REPORT(filter)
    },
    getLabelText: function (value) {
      let index = this.reportLabelTextList.findIndex(x => x.value === value)
      let result = index !== -1 ? this.reportLabelTextList[index].text : ''
      return result
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_DATA_FOR_PAGE_REPORT'
    ])
  }
}
</script>
<style scoped>
table#reportListTable th:nth-child(1) {
  width: 13%;
  white-space: nowrap;
}
table#reportListTable th,td {
  padding: 15px;
}
table#reportListTable td {
  padding: 15px;
}
table#reportListTable tr {
  border-top: 2px solid white;
}
</style>
